import React from 'react'
import {
  CTA1,
  CTA2,
  DividerImg,
  Heading,
  Img,
  LogoContainer,
  LogoTitle,
  MainContainer,
  SubHeading,
  Title
} from './HeroText.styles'
import LogoImg from '../../assets/img/finwiseguide-logo.png'
import HeroDivider from '../../assets/img/hero-divider.png'

const DebtText = () => {
  return (
    <MainContainer>
      <LogoContainer>
        <Img src={LogoImg} alt='Financial Wise Guidance logo' />
        <LogoTitle>Financial Wise Guidance</LogoTitle>
      </LogoContainer>

      <Heading>Find Your Path to Financial Freedom</Heading>
      <SubHeading>
        Use Our Debt Relief Payment Calculator to Estimate Your Savings - Fast,
        Easy, and Secure
      </SubHeading>
      <CTA1>Calculate Your Payment Now and Request a Call</CTA1>
      <CTA2>
        or Reach Us at <a href='tel:+18447690007'>+1 (844) 769 - 0007</a>, Monday to Friday
      </CTA2>
      <CTA2>
        8 AM to 4 PM PST for a <span>FREE CONSULTATION!</span>
      </CTA2>

      <DividerImg src={HeroDivider} />
    </MainContainer>
  )
}

export default DebtText

import React, { useState, useMemo } from 'react';


export const DisclaimerContext = React.createContext();

const DisclaimerProvider = ({ children }) => {
  const [displayDisclaimer, setDisplayDisclaimer] = useState(false);
  const value = useMemo(() => ({ displayDisclaimer, setDisplayDisclaimer }), [displayDisclaimer]);
  return (
    <DisclaimerContext.Provider value={value}>
      {children}
    </DisclaimerContext.Provider>
  );
};

export default DisclaimerProvider;

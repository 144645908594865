import styled from "styled-components";

export const MainContainer = styled.div`
width: 95%;
margin: auto;
background-color: #f4f4f4;
border-radius: 5px;
padding: 50px;
padding-bottom: 0;
box-sizing: border-box;
margin-top: 20px;

`

export const Heading = styled.div`
font-size: 30px;
font-weight: bold;
text-align: center;
margin-bottom: 15px;
`

export const SubHeading = styled.div`
font-size: 20px;
text-align: center;
margin-bottom: 40px;
`

// export const MainContent = styled.div`
// display: flex;
// justify-content: space-between;
// `

export const ReviewsRow = styled.div`
width: 1000px;
margin: auto;
display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: center;

    @media only screen and (max-width: 1250px) {

      /* width: 700px; */
      width: 100%;
      grid-template-columns: 1fr;
      gap: 10px;

}

    @media only screen and (max-width: 950px) {

      width: 100%;
      grid-template-columns: 1fr;
      gap: 10px;


}

`

export const ReviewsCol = styled.div`
/* width: 500px; */
display: grid;
    grid-template-rows: repeat(2, 1fr); /* Ensures equal height for items */
    gap: 10px;

    @media only screen and (max-width: 950px) {

      grid-template-rows: 1fr;
      margin-bottom: 20px;

}
`

export const Review = styled.div`
line-height: 1.5;
text-align: justify;
`

export const NameContainer = styled.div`
display: flex;
height: 40px;
align-items: center;
`

export const ReviewUserImg = styled.img`
width: 33px;
height: 33px;
border-radius: 50%;
margin-right: 15px;

`

export const ReviewUserName = styled.div`
font-size: 16px;
font-weight: bold;
`

import styled from "styled-components";

export const MainContainer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;

  &.home {
    background-color: black;
  }
`;

export const FooterContent = styled.p`
  color: #212529;
  text-align: center;
  font-size: 14px;
  margin: 10px auto 7px;
  line-height: 1.75;

  a{
    font-weight: bold;
    color: #212529;
    text-decoration: none;
  }
`;

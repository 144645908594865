import React, { useContext } from 'react'
import {
  CloseIcon,
  CloseIconContainer,
  MainContainer,
  MainContent,
  TextContent,
  TextContentContainer
} from './TermAndConditions.styles'

import { TermsContext } from '../../../contexts/terms-context'

const TermAndConditions = () => {
  const { setDisplayTerms } = useContext(TermsContext)

  return (
    <MainContainer className='MainContainer'>
      <MainContent className='MainContent'>
        <CloseIconContainer className='CloseIconContainer'>
          <CloseIcon onClick={() => setDisplayTerms(false)}>x</CloseIcon>
        </CloseIconContainer>
        <TextContentContainer className='TextContentContainer'>
          <TextContent className='TextContent'>
            By submitting this form, you acknowledge and agree that all
            information you provide, including but not limited to your name,
            phone number, email address, and the IP address from which you are
            accessing this form, will be collected and stored by Financial Wise
            Guidance and its affiliates. Your personal information will be used
            to communicate with you regarding your inquiries, to provide
            services you have requested, and to offer additional services that
            may be of interest to you. We may also use this information for
            internal analytical purposes to improve our offerings. We take the
            privacy and security of your information seriously and will not
            share your personal details with third parties without your explicit
            consent, except as required by law. Your participation and provision
            of this information signify your consent to these terms. Please be
            assured that all information is stored securely and in accordance
            with relevant data protection laws. For further details, please
            refer to our Privacy Policy. If you have any questions or concerns
            regarding the use of your information, feel free to contact us at{' '}
            <a href='tel:+18447690007'>(844) 769 - 0007</a>.
          </TextContent>
        </TextContentContainer>
      </MainContent>
    </MainContainer>
  )
}

export default TermAndConditions

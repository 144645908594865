import React, { useState, useContext } from 'react';
import TermAndConditions from '../../components/modals/terms/TermAndConditions';
import Disclaimer from '../../components/modals/disclaimer/Disclaimer';
import { TermsContext } from '../../contexts/terms-context';
import { DisclaimerContext } from '../../contexts/disclaimer-context';
import { addDoc, loanRef, doc, serverTimestamp, updateDoc } from '../../config';
import { LeftColContainer, MainContainer, RightColContainer, TermsBackdrop } from './Loan.styles';
import LoanText from '../../components/hero-text/LoanText';
import LoanPaymentCalculator from '../../components/calculators/loan/LoanPaymentCalculator';
import LoanLeadForm from '../../components/lead-forms/loan/LoanLeadForm';
import LoanThankYou from '../../components/thank-you/loan/LoanThankYou';
import LoanReviews from '../../components/reviews/LoanReviews';

const Loan = () => {
  const [currentStep, setCurrentStep] = useState('calculator')
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'state': '',
    'loanAmount': 0,
    'term': 0,
    'rate': 0,
    'score': '',
    'payment': 0
  })
  const { displayTerms, setDisplayTerms } = useContext(TermsContext)
  const { displayDisclaimer, setDisplayDisclaimer } = useContext(DisclaimerContext)

  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_LOAN_TEMPLATE_ID
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

  const fetchIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP Address:', error);
      return '';
    }
  };

  const submitLead = async (e) => {
    e.preventDefault();

    setSubmitting(true)

    const userIP = await fetchIPAddress();

    const dataToSend = {
      createdAt: serverTimestamp(),
      "firstName": formData.firstName,
      "lastName": formData.lastName,
      "email": formData.email,
      "phone": Number(formData.phone),
      "state": formData.state,
      "loanAmount": Number(formData.loanAmount),
      "term": Number(formData.term),
      "rate": Number(formData.rate),
      "score": formData.score,
      'payment': Number(formData.payment),
      userIP
    }

    try {
      const docRef = await addDoc(loanRef, dataToSend)
      await updateDoc(doc(loanRef, docRef.id), {
        id: docRef.id
      })

    } catch (e) {
      console.error('Error adding document: ', e)
    }

    const data = {
      "service_id": EMAILJS_SERVICE_ID,
      "template_id": EMAILJS_TEMPLATE_ID,
      "user_id": EMAILJS_PUBLIC_KEY,
      "template_params": {
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "email": formData.email,
        "phone": formData.phone,
        "state": formData.state,
        "loanAmount": formData.loanAmount,
        "term": formData.term,
        "rate": formData.rate,
        "score": formData.score,
        'payment': formData.payment,
      }
    }

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      // .then(data => {
      //   console.log(data.status)
      // })
      .catch((error) => {
        console.error('Error:', error);
      });

    setCurrentStep('thankYou')
    setSubmitting(false)
  };

  return (<>
    <MainContainer>
      <LeftColContainer>
        <LoanText></LoanText>
      </LeftColContainer>
      <RightColContainer>


        {
          currentStep === 'calculator' && <LoanPaymentCalculator setCurrentStep={setCurrentStep} setDisplayDisclaimer={setDisplayDisclaimer} formData={formData} setFormData={setFormData} />
        }
        {
          currentStep === 'leadForm' && <LoanLeadForm submitting={submitting} submitLead={submitLead} formData={formData} setFormData={setFormData} setDisplayTerms={setDisplayTerms} setCurrentStep={setCurrentStep} />
        }
        {
          currentStep === 'thankYou' && <LoanThankYou submitLead={submitLead} formData={formData} setFormData={setFormData} />
        }
      </RightColContainer>

    </MainContainer>

    <LoanReviews />

    {displayTerms && <TermsBackdrop />}
    {displayTerms && <TermAndConditions />}
    {displayDisclaimer && <TermsBackdrop />}
    {displayDisclaimer && <Disclaimer />}</>
  );
};

export default Loan;

import styled from "styled-components";
import HeroImage from '../../assets/img/backdrop.png'

export const MainContainer = styled.div`
width: 100%;
height: 600px;
position: relative;
background-image: url(${HeroImage});
background-position: left;

  /* Do not repeat the image */
  background-repeat: no-repeat;

  /* The image size will not change */
  background-size: cover;

  /* The image is fixed in the viewport */
  background-attachment: fixed;
`

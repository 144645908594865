import styled from "styled-components";

export const MainContainer = styled.div`
  margin: auto;
  padding: 20px;
  width: 95%;
  max-width: 500px;
  min-width: 280px;
  display: flex;
  flex-direction: column;



  @media only screen and (max-width: 950px) {

flex-direction: column;
padding: 0;

}
`

export const LogoContainer = styled.div`
height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 45px;

  @media only screen and (max-width: 950px) {

width: 100%;
justify-content: center;


}
`

export const Img = styled.img`
width: 50px;
height: 50px;
background-color: black;
border-radius: 50%;
margin-right: 10px;
`

export const LogoTitle = styled.div`
font-size: 20px;
font-weight: bold;
color: gray;
`

export const Heading = styled.div`
font-size: 24px;
color: gray;
margin-bottom: 35px;
font-weight: bold;

@media only screen and (max-width: 950px) {

text-align: center;
margin-bottom: 20px;

}
`

export const SubHeading = styled.div`
font-size: 20px;
color: gray;
line-height: 1.5;
margin-bottom: 50px;
font-weight: 500;

@media only screen and (max-width: 950px) {

text-align: center;
margin-bottom: 20px;



}
`

export const CTA1 = styled.div`
font-size: 18px;
color: gray;
line-height: 1.5;
font-weight: 500;

@media only screen and (max-width: 950px) {

text-align: center;



}
`

export const CTA2 = styled.div`
font-size: 18px;
color: gray;
line-height: 1.5;
font-weight: 500;
text-align: justify;
@media only screen and (max-width: 950px) {

text-align: center;

}

a {
    color: gray;
    cursor: pointer;
    font-weight: bold;
  }
  span {
  font-weight: bold;

}
`


export const DividerImg = styled.img`
width: 330px;
margin: auto;
margin-top: 20px;
transform: translateX(-40px);


@media only screen and (max-width: 950px) {

text-align: center;
margin-bottom: 20px;

transform: none;

}
`

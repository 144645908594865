import { collection, updateDoc, getFirestore, serverTimestamp, onSnapshot, Timestamp, addDoc, query, where, getDoc, getDocs, doc, setDoc } from 'firebase/firestore'
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA30uFm6J60bVPaU5Q7S3Lc1xZG1J4Tc8U",
  authDomain: "leads-93796.firebaseapp.com",
  projectId: "leads-93796",
  storageBucket: "leads-93796.appspot.com",
  messagingSenderId: "813108319482",
  appId: "1:813108319482:web:2b1942fa6fec2dfacb6543"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore()

const debtRef = collection(db, 'debt')
const loanRef = collection(db, 'loan')

export { debtRef, loanRef, addDoc, updateDoc, doc, setDoc, getDoc, getDocs, db, serverTimestamp, query, where, onSnapshot, Timestamp }

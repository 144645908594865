import { Route, Routes } from 'react-router-dom';
import Layout from './layouts/layout';
import Home from './pages/home/Home';
import Debt from './pages/debt/Debt';
import TermsProvider from './contexts/terms-context';
import DisclaimerProvider from './contexts/disclaimer-context';
import Loan from './pages/loan/Loan';

function App() {
  return (
    <TermsProvider>
      <DisclaimerProvider>
        <Layout>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/debt" element={<Debt />} />
            <Route path="/loan" element={<Loan />} />
          </Routes>
        </Layout>
      </DisclaimerProvider>
    </TermsProvider>
  );
}

export default App;

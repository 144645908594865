import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: auto;

  @media only screen and (max-width: 950px) {

    flex-direction: column;

  }
`

export const LeftColContainer = styled.div`

`

export const RightColContainer = styled.div`

`

export const TermsBackdrop = styled.div`
position: fixed;
  background-color: black;
  opacity: .9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

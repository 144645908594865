import styled from "styled-components";


export const MainContainer = styled.div`
width: 100%;
height: 100vh;
box-sizing: border-box;
padding: 10px;
padding-top: 50px;
position: absolute;
top: 0;
`

export const HomeContainer = styled.div`
width: 100%;
min-height: calc(100vh - 42px);
box-sizing: border-box;
padding: 10px;
padding-top: 50px;
background-color: black;
`

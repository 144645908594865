import React from 'react';
import { useLocation } from 'react-router-dom';
import { HomeContainer, MainContainer } from './layout.styles';
import Footer from '../components/nav/footer/Footer';
import Hero from '../components/hero/Hero';

const Layout = ({ children }) => {

  const location = useLocation();

  console.log("The current path is:", location.pathname);

  return (
    <>
    {
      location.pathname === '/' && <><HomeContainer>
        {children}

      </HomeContainer><Footer page='home' /></>
    }
      {location.pathname !== '/' && <>
      <Hero />

      <MainContainer>
        {children}
        <Footer />
      </MainContainer>
      </>}

    </>
  );
};

export default Layout;

import React, { useEffect } from 'react';
import { CTA, CalcContainer, CalcItem, CalcItemContainer, CalcItemDescription, CalcItemImg, CalcItemTitle, Header, Logo, LogoContainer, MainContainer, MainContent, SubHeader, Title } from './Home.styles';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/img/finwiseguide-logo.png'
import DebtCalculator from '../../assets/img/debt-calculator.png'
import LoanCalculator from '../../assets/img/loan-calculator.png'


const Home = () => {
  const navigate = useNavigate();

  // useEffect(() => {

  //   navigate('/debt');

  // }, []);


  return (
    <MainContainer>
      <MainContent>
        <LogoContainer>
        <a href="https://finwiseguide.com">
          <Logo src={LogoImg} alt='Financial Wise Guidance logo' /></a>
          <Title>

            Financial Wise Guidance
          </Title>
        </LogoContainer>
        <Header>
          Navigating Towards Financial Independence with Professional Support
        </Header>
        <SubHeader>
          Financial Wise Guidance is dedicated to offering an extensive array of tailored financial solutions to support individuals, families, and businesses in their journey towards effective debt management and achieving long-term financial health. Our suite of services encompasses specialized Lending (Loan) options, strategic Debt Settlement, and streamlined Debt Consolidation Services. We focus on crafting customized financial strategies that resonate with the specific circumstances of our clients, thereby promoting financial stability and guiding them towards a future of financial independence.
        </SubHeader>
        <CTA>Calculate Your Payment Now and Request a Call or Reach Us at <a href='tel:+18447690007'>+1 (844) 769 - 0007</a>, Monday to Friday 8 AM to 4 PM PST for a <span>FREE CONSULTATION!</span></CTA>
        <CalcContainer>
          <CalcItem onClick={() => navigate('/loan')}>
            <CalcItemImg src={LoanCalculator} />
            <CalcItemContainer>
              <CalcItemTitle>Loan Payment Calculator</CalcItemTitle>
              <CalcItemDescription>
                Navigate Your Loan Repayment with Ease Explore Our Loan Payment Calculator for an Quick Estimates
              </CalcItemDescription>
            </CalcItemContainer>
          </CalcItem>
          <CalcItem onClick={() => navigate('/debt')}>
            <CalcItemImg src={DebtCalculator} />
            <CalcItemContainer>
              <CalcItemTitle>Debt Relief Payment Calculator</CalcItemTitle>
              <CalcItemDescription>Use Our Debt Relief Payment Calculator to Estimate Your Savings - Fast, Easy, and Secure</CalcItemDescription>

            </CalcItemContainer>
          </CalcItem>
        </CalcContainer>
      </MainContent>
    </MainContainer>
  );
};

export default Home;

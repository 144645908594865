import React from 'react'
import { Button, MainContainer, SubTitle, Title } from './DebtThankYou.styles'

const DebtThankYou = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://finwiseguide.com'
  }

  return (
    <MainContainer>
      <Title>Thank you!</Title>
      <SubTitle>One of our consultants will be in touch shortly!</SubTitle>
      <Button onClick={handleButtonClick}>Financial Wise Guidance</Button>
    </MainContainer>
  )
}

export default DebtThankYou

import React from 'react';
import { MainContainer } from './Hero.styles';

const Hero = () => {
  return (
    <MainContainer>

    </MainContainer>
  );
};

export default Hero;

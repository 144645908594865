import React from 'react'
import { LineWave } from 'react-loader-spinner'
import { SpinnerContainer } from './Spinner.styles'

const Spinner = () => {
  return (
    <SpinnerContainer>
      <LineWave
        height='100'
        width='100'
        color='#1b6489'
        ariaLabel='line-wave'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        firstLineColor=''
        middleLineColor=''
        lastLineColor=''
      />
    </SpinnerContainer>
  )
}

export default Spinner

import React, { useState, useMemo } from 'react';


export const TermsContext = React.createContext();

const TermsProvider = ({ children }) => {
  const [displayTerms, setDisplayTerms] = useState(false);
  const value = useMemo(() => ({ displayTerms, setDisplayTerms }), [displayTerms]);
  return (
    <TermsContext.Provider value={value}>
      {children}
    </TermsContext.Provider>
  );
};

export default TermsProvider;

import React, { useState, useEffect } from 'react'
import {
  Button,
  Disclaimer,
  Form,
  Input,
  Label,
  MainContainer,
  Message,
  MessageContainer,
  Select
} from './LoanPaymentCalculator.styles'

const LoanPaymentCalculator = ({
  setDisplayDisclaimer,
  formData,
  setFormData,
  setCurrentStep
}) => {
  const [amount, setAmount] = useState(undefined)
  const [rate, setRate] = useState(undefined)
  const [term, setTerm] = useState(undefined)
  const [score, setScore] = useState('')
  const [monthlyPayment, setMonthlyPayment] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    if (!monthlyPayment) return

    setFormData({
      ...formData,
      loanAmount: parseFloat(amount).toFixed(2),
      rate: rate,
      term: term,
      score: score,
      payment: monthlyPayment
    })

    setCurrentStep('leadForm')
  }

  const handleAmountChange = e => {
    setAmount(parseInt(e.target.value))
  }

  const handleRateChange = e => {
    e.target.value > 100 ? setRate(100) : setRate(e.target.value)
  }

  const handleTermChange = e => {
    e.target.value > 600 ? setTerm(600) : setTerm(e.target.value)
  }

  const handleScoreChange = e => {
    setScore(e.target.value)
  }

  const handleDisplayDisclaimer = () => {
    setDisplayDisclaimer(true)
  }

  useEffect(() => {
    if (!amount || !rate || !term) {
      setMonthlyPayment(undefined)
      return
    }

    if (amount <= 0 || rate <= 0 || term <= 0) {
      setMonthlyPayment(undefined)
      setErrorMessage(true)
      return
    } else {
      setErrorMessage(false)
    }

    const principal = parseFloat(amount)
    const monthlyInterestRate = parseFloat(rate) / 100 / 12
    const numberOfPayments = parseFloat(term)

    let payment
    if (monthlyInterestRate === 0) {
      // Handle no interest
      payment = principal / numberOfPayments
    } else {
      payment =
        (principal *
          (monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
    }
    setMonthlyPayment(payment.toFixed(2))
  }, [amount, rate, term])

  return (
    <MainContainer>
      <Form id='LoanPaymentCalculator' onSubmit={handleSubmit}>
        <Label htmlFor='amount'>Desired Loan Amount ($):</Label>
        <Input
          required
          type='number'
          id='amount'
          value={amount ? amount : undefined}
          onChange={handleAmountChange}
          placeholder='0'
        />

        <Label htmlFor='rate'>Estimated Interest Rate (%)</Label>
        <Input
          required
          type='number'
          id='rate'
          value={rate ? rate : undefined}
          onChange={handleRateChange}
          placeholder='0'
        />

        <Label htmlFor='term'>Desired Loan Term (months)</Label>
        <Input
          required
          type='number'
          id='term'
          value={term ? term : undefined}
          onChange={handleTermChange}
          placeholder='0'
        />

        <Label htmlFor='term'>Your Credit Score Range</Label>
        <Select required id='term' value={score} onChange={handleScoreChange}>
          <option value='' defaultValue disabled>
            Select your credit score range
          </option>

          <option value='Excellent (750-850)'>Excellent (750-850)</option>
          <option value='Good (700-749)'>Good (700-749)</option>
          <option value='Fair (640-699)'>Fair (640-699)</option>
          <option value='Poor (639 or less)'>Poor (639 or less)</option>
        </Select>

        <Button
          type='submit'
          className={(!monthlyPayment || !score) && 'disabled'}
          disabled={!monthlyPayment || !score}
        >
          Next
        </Button>
        <MessageContainer>
          {errorMessage && (
            <Message type='error'>ENTER A VALUE HIGHER THAN 0</Message>
          )}
          {monthlyPayment && (
            <Message>{`YOUR MONTHLY PAYMENT: $${monthlyPayment}`}</Message>
          )}
        </MessageContainer>
      </Form>

      <Disclaimer onClick={handleDisplayDisclaimer}>
        <strong>Disclaimer:</strong> The calculated monthly payment provided
        here is an estimate based on the inputs you have provided. This is
        intended for informational purposes only and should not be considered as
        a definitive or gu ...
      </Disclaimer>
    </MainContainer>
  )
}

export default LoanPaymentCalculator

import styled from "styled-components";
import SelectArrowImg from '../../../assets/img/arrow-down.png'

export const MainContainer = styled.div`
  margin: auto;
  padding: 20px;
  width: 95%;
  max-width: 400px;
  min-width: 280px;
  background-color: rgba(0,0,20, 0.6);
  border-radius: 5px;
  box-sizing: border-box;
`;

export const Form = styled.form`
  width: 100%;
`;

export const InputRow = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
column-gap: 10px;
`

export const InputRowCol = styled.div`
width: 100%;

&.termsInputCols {
  width: 20px;
  margin-right: 20px;
  margin-bottom: 10px;

  input {
    margin-left: 0;
    cursor: pointer;
    transform: translateY(1px);
  }

}
`

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: 1px solid #1b6489;
  box-sizing: border-box;
  font-size: 16px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: whitesmoke;
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #1b6489;
  background-color: #8d9ba0;
  border-radius: 5px;
  transition: all 0.4s;

  &:hover {
    background-color: #1b6489;
    color: whitesmoke;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  padding: 10px;
  padding-right: 25px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: 1px solid #1b6489;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;

  background-image: url(${SelectArrowImg});
  background-repeat: no-repeat;
  background-position: right 10px center;
`;

export const Disclaimer = styled.div`
  font-size: 14px;
  color: #8d9ba0;
  text-align: justify;
  display: flex;
  line-height: 1.4;
  transform: translateY(-4px);

  span.termsLink {
    color: red;
    cursor: pointer;
  }
`;

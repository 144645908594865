import styled from "styled-components";

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    transform: translate(10px, -46px);
  }
`;

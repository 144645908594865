import React from 'react'
import {
  Heading,
  MainContainer,
  NameContainer,
  Review,
  ReviewUserImg,
  ReviewUserName,
  ReviewsCol,
  ReviewsRow,
  SubHeading
} from './Reviews.styles'
import DavidWilsonAvatar from '../../assets/img/avatars/david-wilson.png'
import EmmaRodriguezAvatar from '../../assets/img/avatars/emma-rodriguez.png'
import JohnCarterAvatar from '../../assets/img/avatars/john-carter.png'
import SarahThompsonAvatar from '../../assets/img/avatars/sarah-thompson.png'

const DebtReviews = () => {
  return (
    <MainContainer>
      <Heading>Client Testimonials</Heading>
      <SubHeading>Hear What Our Clients Have to Say About Us</SubHeading>
      <ReviewsRow>
        <ReviewsCol>
          <Review>
            Financial Wise Guidance's expert team provided me with personalized
            guidance throughout my financial hardship. Their compassionate
            approach, coupled with their in-depth knowledge of the industry,
            made all the difference in my journey to financial freedom.
          </Review>
          <NameContainer>
            <ReviewUserImg src={SarahThompsonAvatar} />
            <ReviewUserName>SARAH THOMPSON</ReviewUserName>
          </NameContainer>
        </ReviewsCol>

        <ReviewsCol>
          <Review>
            The team at Financial Wise Guidance provided me with invaluable
            support throughout my Debt Settlement journey. Their professionalism
            and dedication to my financial well-being were second to none.
          </Review>
          <NameContainer>
            <ReviewUserImg src={JohnCarterAvatar} />
            <ReviewUserName>JOHN CARTER</ReviewUserName>
          </NameContainer>
        </ReviewsCol>
      </ReviewsRow>

      <ReviewsRow>
        <ReviewsCol>
          <Review>
            Working with Financial Wise Guidance allowed me to consolidate my
            debts into one manageable payment, and their Hardship Program
            provided me with a personalized solution that fit my needs. I am so
            grateful for their support.
          </Review>
          <NameContainer>
            <ReviewUserImg src={EmmaRodriguezAvatar} />
            <ReviewUserName>EMMA RODRIGUEZ</ReviewUserName>
          </NameContainer>
        </ReviewsCol>

        <ReviewsCol>
          <Review>
            Thanks to Financial Wise Guidance's expert guidance, I was able to
            overcome my financial hardship and achieve financial freedom. Their
            Debt Consolidation service allowed me to gain control of my
            finances, and their unwavering support made all the difference.
          </Review>
          <NameContainer>
            <ReviewUserImg src={DavidWilsonAvatar} />
            <ReviewUserName>DAVID WILSON</ReviewUserName>
          </NameContainer>
        </ReviewsCol>
      </ReviewsRow>
    </MainContainer>
  )
}

export default DebtReviews

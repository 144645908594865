import styled from "styled-components";

export const MainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const MainContent = styled.div`
background-color: #f4f4f4;
width: 95%;
margin: auto;
position: fixed;
top: 50px;
left: 50%;
transform: translateX(-50%);
padding: 20px;
box-sizing: border-box;
border-radius: 5px;
`

export const CloseIconContainer = styled.div`
width: 100%;
text-align: right;
margin-bottom: 5px;
transform: translate(-5px, -5px);
color: red;
`

export const CloseIcon = styled.p`
display: inline;
cursor: pointer;
`

export const TextContent = styled.div`
width: 100%;
text-align: justify;
color: black;
line-height: 1.5;

span {
  font-weight: bold;
}

a{
  color: black;
}
`

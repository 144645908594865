import React from "react";
import { FooterContent, MainContainer } from "./Footer.styles";

const Footer = ({page}) => {
  return (
    <MainContainer className={page}>
      <FooterContent>
      © {new Date().getFullYear()} <a href="https://finwiseguide.com">Financial Wise Guidance</a>. All Rights Reserved.
      </FooterContent>
    </MainContainer>
  );
};

export default Footer;

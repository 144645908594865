import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`

export const MainContent = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

export const LogoContainer = styled.div`
height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 45px;

  @media only screen and (max-width: 950px) {

width: 100%;
justify-content: center;


}
`

export const Title = styled.div`
font-size: 20px;
font-weight: bold;
color: gray;

`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
`

export const Header = styled.div`
  font-size: 24px;
color: gray;
margin-bottom: 35px;
font-weight: bold;

@media only screen and (max-width: 950px) {

text-align: center;
margin-bottom: 20px;

}
`

export const SubHeader = styled.div`
  font-size: 20px;
color: gray;
line-height: 1.5;
margin-bottom: 35px;
font-weight: 500;
text-align: justify;

@media only screen and (max-width: 950px) {

text-align: center;
margin-bottom: 20px;



}
`

export const CTA = styled.div`
font-size: 18px;
color: gray;
line-height: 1.5;
font-weight: 500;
margin-bottom: 35px;

a {
    color: gray;
    cursor: pointer;
    font-weight: bold;
  }
  span {
  font-weight: bold;

}

@media only screen and (max-width: 950px) {

text-align: center;



}
`

export const CalcContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;

color: gray;

@media only screen and (max-width: 1030px) {

flex-direction: column;
gap: 20px;
margin-bottom: 10px;



}


`

export const CalcItem = styled.div`
display: flex;
border: 1px solid #EFC75E;
padding-right: 20px;
border-radius: 5px;

cursor: pointer;
transition: all .3s ease-in-out;

&:hover {
  transform: scale(1.01);
}

@media only screen and (max-width: 1030px) {

width: 90%;
flex-direction: column;
align-items: center;
padding-right: 0;





}
`


export const CalcItemImg = styled.img`
width: 150px;
height: 150px;
margin-right: 20px;
border-radius: 5px;

@media only screen and (max-width: 1030px) {

width: 100%;
object-fit: cover;
margin-right: 0;
margin-bottom: 20px;



}
`

export const CalcItemContainer = styled.div`
width: 300px;
height: 150px;
display: flex;
flex-direction: column;
justify-content: center;

@media only screen and (max-width: 1030px) {

height: 100%;
width: calc(100% - 20px);
margin-bottom: 15px;


}
`

export const CalcItemTitle = styled.div`
width: 100%;
margin-bottom: 20px;
font-weight: bold;
`

export const CalcItemDescription = styled.div`
width: 100%;
/* text-align: justify; */
line-height: 20px;
`

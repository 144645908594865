import React, { useState, useContext } from 'react';
import { LeftColContainer, MainContainer, RightColContainer, TermsBackdrop } from './Debt.styles';
import DebtReliefPaymentCalculator from '../../components/calculators/debt/DebtReliefPaymentCalculator';
import DebtReliefLeadForm from '../../components/lead-forms/debt/DebtReliefLeadForm';
import TermAndConditions from '../../components/modals/terms/TermAndConditions';
import { TermsContext } from '../../contexts/terms-context';
import DebtThankYou from '../../components/thank-you/debt/DebtThankYou';
import { addDoc, debtRef, doc, serverTimestamp, updateDoc } from '../../config';
import DebtText from '../../components/hero-text/DebtText';
import DebtReviews from '../../components/reviews/DebtReviews';


const Debt = () => {
  const [currentStep, setCurrentStep] = useState('calculator')
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'state': '',
    'debtAmount': 0,
    'term': 0,
  })
  const { displayTerms, setDisplayTerms } = useContext(TermsContext)

  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

  const fetchIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP Address:', error);
      return '';
    }
  };

  const submitLead = async (e) => {
    e.preventDefault();

    setSubmitting(true)

    const userIP = await fetchIPAddress();

    try {
      const docRef = await addDoc(debtRef, {
        createdAt: serverTimestamp(),
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "email": formData.email,
        "phone": formData.phone,
        "state": formData.state,
        "debtAmount": formData.debtAmount,
        "term": formData.term,
        userIP
      })

      // Then, update the document with its generated ID
      await updateDoc(doc(debtRef, docRef.id), {
        id: docRef.id
      })

    } catch (e) {
      console.error('Error adding document: ', e)
    }

    const data = {
      "service_id": EMAILJS_SERVICE_ID,
      "template_id": EMAILJS_TEMPLATE_ID,
      "user_id": EMAILJS_PUBLIC_KEY,
      "template_params": {
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "email": formData.email,
        "phone": formData.phone,
        "state": formData.state,
        "debtAmount": formData.debtAmount,
        "term": formData.term
      }
    }

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      // .then(data => {
      //   console.log(data)
      // })
      .catch((error) => {
        console.error('Error:', error);
      });

    setCurrentStep('thankYou')
    setSubmitting(false)
  };

  return (<>
    <MainContainer>
      <LeftColContainer>
        <DebtText></DebtText>
      </LeftColContainer>
      <RightColContainer>

        {
          currentStep === 'calculator' && <DebtReliefPaymentCalculator setSubmitting={setSubmitting} formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />
        }
        {
          currentStep === 'leadForm' && <DebtReliefLeadForm submitting={submitting} submitLead={submitLead} formData={formData} setFormData={setFormData} setDisplayTerms={setDisplayTerms} setCurrentStep={setCurrentStep} />
        }
        {
          currentStep === 'thankYou' && <DebtThankYou submitLead={submitLead} formData={formData} setFormData={setFormData} />
        }
      </RightColContainer>


    </MainContainer>
    <DebtReviews />

    {displayTerms && <TermsBackdrop />}
    {displayTerms && <TermAndConditions />}</>
  );
};

export default Debt;

import React, { useState, useEffect } from 'react'
import {
  Button,
  Disclaimer,
  Form,
  Input,
  Label,
  MainContainer,
  Message,
  MessageContainer,
  Select
} from './DebtReliefPaymentCalculator.styles'
import { debtStates } from '../../../data/data'

const DebtReliefPaymentCalculator = ({
  setCurrentStep,
  setFormData,
  formData
}) => {
  const [state, setState] = useState('')
  const [amount, setAmount] = useState('')
  const [term, setTerm] = useState('')
  const [termsOptions, setTermsOptions] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [paymentResult, setPaymentResult] = useState('')
  const [isPaymentCalculated, setIsPaymentCalculated] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState(null)

  const { allStates, blueStates, purpleStates } = debtStates

  const handleStateChange = e => {
    setState(e.target.value)
    setIsPaymentCalculated(false)
    setPaymentResult(false)
  }

  const handleAmountChange = e => {
    const amountValue = parseInt(e.target.value, 10)
    setPaymentResult(false)
    setAmount(amountValue)
    setTerm('')
    setTermsOptions([])
    if (isNaN(amountValue) || amountValue < 7500) {
      setErrorMessage(true)
    } else {
      setErrorMessage('')
      updateTermOptions(amountValue)
    }
  }

  const handleTermChange = e => {
    setPaymentResult(false)
    setIsPaymentCalculated(false)
    setTerm(e.target.value)
  }

  const updateTermOptions = amount => {
    let options = []

    if (amount >= 7500 && amount <= 14999) {
      options = [30, 42]
    } else if (amount >= 15000 && amount <= 19999) {
      options = [30, 46, 42]
    } else if (amount >= 20000 && amount <= 24999) {
      options = [36, 48]
    } else if (amount >= 25000 && amount <= 44999) {
      options = [36, 42, 48]
    } else if (amount >= 45000 && amount <= 99999) {
      options = [42, 48, 54]
    } else if (amount >= 100000) {
      options = [42, 48, 60]
    }

    setTermsOptions(options)
  }

  const calculateFee = (state, amount) => {
    let percent = 0
    if (blueStates.includes(state)) {
      if (amount <= 14999) {
        percent = 0.29
      } else if (amount >= 15000 && amount <= 44999) {
        percent = 0.27
      } else if (amount >= 45000) {
        percent = 0.25
      }
    } else if (purpleStates.includes(state)) {
      percent = 0.29
    }
    return amount * percent
  }

  const calculateSettleAmount = amount => {
    return amount * 0.55
  }

  const calculatePayment = () => {
    if (state && amount && term) {
      const settleAmount = calculateSettleAmount(amount)
      const fees = calculateFee(state, amount)
      const payment = (settleAmount + fees) / term
      const formattedAmount = payment
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      setPaymentAmount(formattedAmount)
      setPaymentResult(true)
      setIsPaymentCalculated(true)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    setFormData({ ...formData, state: state, debtAmount: amount, term: term })

    setCurrentStep('leadForm')
  }

  useEffect(() => {
    calculatePayment()
  }, [state, amount, term])

  return (
    <MainContainer>
      <Form id='DebtReliefCalculator' onSubmit={handleSubmit}>
        <Label htmlFor='state'>State:</Label>
        <Select required id='state' value={state} onChange={handleStateChange}>
          <option value='' disabled={state !== ''}>
            Please select your state
          </option>
          {allStates.map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>

        <Label htmlFor='amount'>Amount ($):</Label>
        <Input
          required
          type='number'
          id='amount'
          value={amount}
          onChange={handleAmountChange}
          placeholder='0'
        />

        <Label htmlFor='term'>Term (months):</Label>
        <Select required id='term' value={term} onChange={handleTermChange}>
          <option value='' disabled={state !== ''}>
            Please select your term
          </option>
          {termsOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>

        <Button
          type='submit'
          className={!isPaymentCalculated && 'disabled'}
          disabled={!isPaymentCalculated}
        >
          Next
        </Button>
        <MessageContainer>
          {errorMessage && (
            <Message type='error'>
              AMOUNT NEEDS TO BE HIGHER THAN $7,500
            </Message>
          )}
          {paymentResult && (
            <Message>{`YOUR MONTHLY PAYMENT: $${paymentAmount}`}</Message>
          )}
        </MessageContainer>
      </Form>

      <Disclaimer>
        <strong>Disclaimer:</strong> The calculated monthly payment provided
        here is an estimate based on the inputs you have provided. This is
        intended for informational purposes only and should not be considered as
        a definitive or guaranteed payment amount. Actual payment amounts may
        vary based on individual circumstances, credit history, applicable fees,
        and other factors specific to each case. We encourage you to contact us
        directly for a more accurate and personalized assessment of your
        potential payment obligations.
      </Disclaimer>
    </MainContainer>
  )
}

export default DebtReliefPaymentCalculator

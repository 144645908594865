import React, { useState, useEffect } from 'react'
import {
  Button,
  Disclaimer,
  Form,
  Input,
  InputRow,
  InputRowCol,
  Label,
  MainContainer,
  Select
} from './LoanLeadForm.styles'
import Spinner from '../../spinner/Spinner'
import { loanStates } from '../../../data/data'

const LoanLeadForm = ({
  setDisplayTerms,
  formData,
  setFormData,
  submitLead,
  submitting
}) => {
  const [isComplete, setIsComplete] = useState(false)

  const { allStates } = loanStates

  const handleDisplayTerms = () => {
    setDisplayTerms(true)
  }

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <MainContainer>
      <Form onSubmit={submitLead}>
        <InputRow>
          <InputRowCol>
            <Label htmlFor='firstName'>First Name</Label>
            <Input
              required
              type='text'
              id='firstName'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              placeholder='John'
            />
          </InputRowCol>
          <InputRowCol>
            <Label htmlFor='lastName'>Last Name</Label>
            <Input
              required
              type='text'
              id='lastName'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              placeholder='Doe'
            />
          </InputRowCol>
        </InputRow>
        <InputRow>
          <InputRowCol>
            <Label htmlFor='state'>State:</Label>
            <Select
              required
              id='state'
              name='state'
              value={formData.state}
              onChange={handleChange}
            >
              <option value='' disabled={formData.state !== ''}>
                Please select your state
              </option>
              {allStates.map(state => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
          </InputRowCol>
        </InputRow>
        <InputRow>
          <InputRowCol>
            <Label htmlFor='phone'>Phone</Label>
            <Input
              required
              type='number'
              id='phone'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              placeholder='844 769 0007'
            />
          </InputRowCol>
        </InputRow>
        <InputRow>
          <InputRowCol>
            <Label htmlFor='email'>Email</Label>
            <Input
              required
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder='john.doe@email.com'
            />
          </InputRowCol>
        </InputRow>

        <Disclaimer>
          <InputRowCol className='termsInputCols'>
            <Input
              required
              type='checkbox'
              id='agreeCheckbox'
              name='agreeCheckbox'
            />
          </InputRowCol>
          <InputRowCol>
            By submitting this form, I acknowledge and agree to the{' '}
            <span onClick={handleDisplayTerms} className='termsLink'>
              Terms and Conditions
            </span>
            .
          </InputRowCol>
        </Disclaimer>

        <Button
          type='submit'
          className={!isComplete && 'disabled'}
          disabled={submitting}
        >
          {submitting ? <Spinner /> : 'Submit'}
        </Button>
      </Form>
    </MainContainer>
  )
}

export default LoanLeadForm

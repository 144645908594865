import React, { useContext } from 'react'
import {
  CloseIcon,
  CloseIconContainer,
  MainContainer,
  MainContent,
  TextContent
} from './Disclaimer.styles'

import { DisclaimerContext } from '../../../contexts/disclaimer-context'

const TermAndConditions = () => {
  const { setDisplayDisclaimer } = useContext(DisclaimerContext)

  return (
    <MainContainer>
      <MainContent>
        <CloseIconContainer>
          <CloseIcon onClick={() => setDisplayDisclaimer(false)}>x</CloseIcon>
        </CloseIconContainer>
        <TextContent>
          <span>Disclaimer:</span> The calculated monthly payment provided here is an
          estimate based on the inputs you have provided. This is intended for
          informational purposes only and should not be considered as a
          definitive or guaranteed payment amount. Actual payment amounts may
          vary based on individual circumstances, credit history, applicable
          fees, and other factors specific to each case. We encourage you to
          contact us directly for a more accurate and personalized assessment of
          your potential payment obligations.
        </TextContent>
      </MainContent>
    </MainContainer>
  )
}

export default TermAndConditions

import styled from "styled-components";


export const MainContainer = styled.div`
  margin: auto;
  padding: 20px;
  width: 95%;
  max-width: 400px;
  min-width: 280px;
  text-align: center;
  background-color: rgba(0,0,20, 0.6);
  border-radius: 5px;
  color: white;
  box-sizing: border-box;
`;

export const Title = styled.div`
font-size: 24px;
margin-bottom: 20px;
`

export const SubTitle = styled.div`
font-size: 18px;
margin-bottom: 20px;
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #1b6489;
  background-color: #8d9ba0;
  border-radius: 5px;
  transition: all 0.4s;

  &:hover {
    background-color: #1b6489;
    color: whitesmoke;
  }

`;

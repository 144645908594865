import React from 'react'
import {
  Heading,
  MainContainer,
  NameContainer,
  Review,
  ReviewUserImg,
  ReviewUserName,
  ReviewsCol,
  ReviewsRow,
  SubHeading
} from './Reviews.styles'
import LisaAndersonAvatar from '../../assets/img/avatars/lisa-anderson.png'
import MichaelJonesAvatar from '../../assets/img/avatars/michael-jones.png'
import SarahWilliamsAvatar from '../../assets/img/avatars/sarah-williams.png'
import JohnSmithAvatar from '../../assets/img/avatars/john-smith.png'

const LoanReviews = () => {
  return (
    <MainContainer>
      <Heading>Client Testimonials</Heading>
      <SubHeading>Hear What Our Clients Have to Say About Us</SubHeading>
      <ReviewsRow>
        <ReviewsCol>
          <Review>
            The lending team at Financial Wise Solutions has been a lifesaver
            for me. Their understanding and expertise helped me secure a loan
            when I needed it most. I can't thank them enough for their quick and
            efficient service.
          </Review>
          <NameContainer>
            <ReviewUserImg src={LisaAndersonAvatar} />
            <ReviewUserName>LISA ANDERSON</ReviewUserName>
          </NameContainer>
        </ReviewsCol>

        <ReviewsCol>
          <Review>
            Financial Wise Solutions provided me with a loan that allowed me to
            consolidate my existing debts and reduce my monthly payments
            significantly. Their team walked me through the entire process and
            made it stress-free. I highly recommend their loan services.
          </Review>
          <NameContainer>
            <ReviewUserImg src={MichaelJonesAvatar} />
            <ReviewUserName>MICHAEL JONES</ReviewUserName>
          </NameContainer>
        </ReviewsCol>
      </ReviewsRow>

      <ReviewsRow>
        <ReviewsCol>
          <Review>
            I was in a financial bind, and Financial Wise Solutions came to my
            rescue with their loan services. Their competitive interest rates
            and flexible repayment options made it possible for me to get back
            on track financially. I'm incredibly grateful for their support.
          </Review>
          <NameContainer>
            <ReviewUserImg src={SarahWilliamsAvatar} />
            <ReviewUserName>SARAH WILLIAMS</ReviewUserName>
          </NameContainer>
        </ReviewsCol>

        <ReviewsCol>
          <Review>
            Financial Wise Solutions made the loan application process a breeze.
            Their team helped me secure a loan quickly and easily, and their
            transparent terms and rates gave me peace of mind. Thank you for
            being there when I needed it most.
          </Review>
          <NameContainer>
            <ReviewUserImg src={JohnSmithAvatar} />
            <ReviewUserName>JOHN SMITH</ReviewUserName>
          </NameContainer>
        </ReviewsCol>
      </ReviewsRow>
    </MainContainer>
  )
}

export default LoanReviews
